import { WindowLocation } from "@reach/router";
import { graphql, navigate } from "gatsby";
import flatten from "lodash.flatten";
import sortBy from "lodash.sortby";
import uniqBy from "lodash.uniqby";
import React from "react";
import BuyFilter from "../components/BuyFilter/BuyFilter";
import Divider from "../components/Divider/Divider";
import { Col, Row } from "../components/Grid";
import ResellerListing from "../components/ResellerListing/ResellerListing";
import WithSpacing from "../components/Spacing/Spacing";
import Text from "../components/Text/Text";
import GetText, { useString } from "../i18n/GetText";
import BuyPageLayout from "../layouts/BuyPageLayout/BuyPageLayout";
import PageLayout from "../layouts/PageLayout/PageLayout";
import {
  CommonContext,
  SanityCountry,
  SanityEcosystemMember,
  SanityPage,
  SanityRawContent
} from "../model/common";
import { GlobalMenus } from "../model/menu";
import { SiteArea } from "../utils/analytics";
import { useSearchParam } from "../utils/useSearchParam";
import styles from "./WhereToBuyPage.module.scss";

export const pageQuery = graphql`
  query BuyPage($_id: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    page: sanityBuyPage(_id: { eq: $_id }) {
      _id
      _type
      language
      title
      _rawDescription(resolveReferences: { maxDepth: 5 })
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
    }
    resellers: allSanityEcosystemMember(
      # Match everything. Glob or regex give duplicate results.
      filter: { products: { nin: "__" } }
      sort: { order: ASC, fields: name }
    ) {
      nodes {
        _id
        name
        resellerUrl
        channelPartner
        countries {
          code
          name
        }
        logo {
          ...SanityImage
        }
      }
    }
  }
`;

interface WhereToBuyPageProps {
  data: {
    menus: GlobalMenus;
    page: SanityPage & { _rawDescription: SanityRawContent };
    resellers: { nodes: SanityEcosystemMember[] };
  };
  location: WindowLocation;
  pageContext: CommonContext;
}

const WhereToBuyPage = ({
  pageContext,
  data: {
    menus,
    page,
    resellers: { nodes: unfilteredResellers }
  },
  location
}: WhereToBuyPageProps) => {
  const { title, _rawDescription: description } = page;
  const { resellers, navigateWithParams, options, query } = useBuyParams(
    unfilteredResellers
  );
  const [approved, unapproved] = partition(resellers, x => !!x.channelPartner);

  const filterRows = (
    <Row>
      <Col offsetMd={1} md={3} sm={3} xs={12}>
        <BuyFilter
          value={query.location || null}
          placeholderId="location"
          placeholderFallback="Location"
          onChange={value => navigateWithParams({ location: value })}
          options={options.location as any}
          formatOptionLabel={({ label }: any) => (
            <Text as="span" variant="default">
              {label}
            </Text>
          )}
        />
      </Col>
    </Row>
  );

  return (
    <PageLayout
      menus={menus}
      siteArea={SiteArea.BUY}
      metadata={{
        title,
        page,
        alternates: pageContext.alternates
      }}
      location={location}
      strings={pageContext.strings}
    >
      <BuyPageLayout
        title={title}
        description={description}
        filterRows={filterRows}
        gradient="duskRed"
      >
        {approved.length > 0 && (
          <>
            <Row className={styles.channelPartnersRow}>
              <WithSpacing>
                <Col offsetMd={1} md={12}>
                  <Text variant="h2">
                    <GetText
                      id="channel-partners"
                      fallback="Channel partners"
                    />
                  </Text>
                </Col>
              </WithSpacing>
            </Row>
            <ResellerListing resellers={approved} cardTags={cardTags} />
            <Row justify="center">
              <Col md={10}>
                <Divider className={styles.divider} />
              </Col>
            </Row>
          </>
        )}
        <ResellerListing resellers={unapproved} cardTags={cardTags} />
      </BuyPageLayout>
    </PageLayout>
  );
};

const partition = function<T>(
  array: T[],
  predicate: (v: T) => boolean
): [T[], T[]] {
  const a: T[] = [];
  const b: T[] = [];
  array.forEach(item => {
    (predicate(item) ? a : b).push(item);
  });
  return [a, b];
};

const cardTags = ({ channelPartner, countries }: SanityEcosystemMember) => {
  const sortedCountries = sortBy(countries, c => c.name);
  const tags = [sortedCountries.map(c => <span key={c.code}>{c.name}</span>)];
  if (channelPartner) {
    tags.push([
      <span key="channel-partner">
        <GetText id="channel-partner" fallback="Channel partner" />
      </span>
    ]);
  }
  return tags;
};

interface Query {
  // We've had other filters here before and may well again,
  // so leaving some flexibility to add back.
  location?: string | null;
}

const useBuyParams = (unfilteredResellers: SanityEcosystemMember[]) => {
  let location = useSearchParam("location");
  const allLocations = useString("all-locations", "All locations");
  const locations: SanityCountry[] = sortBy(
    uniqBy(flatten(unfilteredResellers.map(r => r.countries)), c => c.code),
    c => c.name
  );
  if (!locations.find(x => x.code === location)) {
    location = null;
  }
  const query: Query = { location };
  const options = {
    location: [{ code: undefined, name: allLocations }, ...locations].map(
      c => ({
        value: c.code,
        label: c.name
      })
    )
  };
  const navigateWithParams = (params: Partial<Query>) => {
    const queryString = Object.entries({ ...query, ...params })
      .filter(([_, v]) => Boolean(v))
      .map(([k, v]) => `${k}=${encodeURIComponent(v!)}`)
      .join("&");
    navigate(
      `${window.location.pathname}${queryString ? `?${queryString}` : ""}`,
      { replace: true }
    );
  };
  const filteredResellers = unfilteredResellers.filter(
    r => !location || r.countries.find(c => c.code === location)
  );
  return {
    options,
    query,
    navigateWithParams,
    resellers: filteredResellers
  };
};

export default WhereToBuyPage;
