import React from "react";
import MaybeLink from "../../components/MaybeLink/MaybeLink";
import GetText from "../../i18n/GetText";
import styles from "./SupplierCardPart.module.scss";
import Text from "../Text/Text";
import ExternalLinkIcon from "../../icons/ExternalLinkIcon";

interface SupplierCardPartProps {
  name: string;
  url?: string;
}

const SupplierCardPart = ({ name, url }: SupplierCardPartProps) => (
  <div className={styles.root}>
    <Text variant="defaultTiny" as="div">
      <GetText id="supplier" />
    </Text>
    <MaybeLink
      to={url}
      rel={process.env.GATSBY_STAGE === "PRODUCTION" ? "noopener" : "noreferer"}
    >
      <Text variant="default" as="span">
        {name}
      </Text>
      {url && <ExternalLinkIcon />}
    </MaybeLink>
  </div>
);

export default SupplierCardPart;
