import React from 'react';
const ExternalLinkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="20"
    viewBox="0 0 23 20"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#6C4BC1"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18.308 7.923l-6.231 6.231M1 1h20.769v18H1zM1 4.462h20.769" />
      <path d="M12.77 7.923h5.538v5.539" />
    </g>
  </svg>
);
export default ExternalLinkIcon;
