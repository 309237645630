import React, { ReactNode } from "react";
import GetText from "../../i18n/GetText";
import styles from "./BuyFilter.module.scss";
import Text from "../Text/Text";
import SelectDropdown, {
  DropdownOption
} from "../../components/SelectDropdown/SelectDropdown";

interface BuyFilterProps {
  options: {
    label?: ReactNode;
    value: string;
  }[];
  value: string | null;
  placeholderId?: string;
  placeholderFallback?: string;
  onChange: (value: string) => void;
  [other: string]: any;
}

const BuyFilter = ({
  options,
  value,
  onChange,
  placeholderId,
  placeholderFallback,
  ...others
}: BuyFilterProps) => (
  <Text variant="default" as="div">
    <SelectDropdown
      onChange={(e: DropdownOption) => onChange(e.value as string)}
      placeholder={
        <span>
          <GetText
            id={placeholderId || "select"}
            fallback={placeholderFallback}
          />
          ...
        </span>
      }
      className={styles.dropdown}
      value={options.find(o => o.value === value) || null}
      isSearchable={false}
      options={options}
      indicatorClassName={styles.indicator}
      overrideStyles={{
        placeholder: {
          color: "white",
          whiteSpace: "nowrap"
        },
        control: {
          border: "solid 1.5px white",
          backgroundColor: "transparent"
        },
        singleValue: {
          color: "white"
        },
        option: () => ({
          height: "auto",
          minHeight: "50px"
        })
      }}
      {...others}
    />
  </Text>
);

export default BuyFilter;
